<template>
  <div class="col-12 text-center">
    <div class="page-transition" style="min-height: 50vh;">
      <img alt="Estimation Done" src="../assets/icons/done.png" />
      <h1 class="text-primary fw-700 py-1 my-0">All Done!</h1>
      <div class="fs-5 fw-500 text-muted col-12 col-lg-6 offset-0 offset-lg-3 px-5">Based on the answers provided, please find your app development cost estimation below.</div>
      <div class="last-summary pt-4 pb-2 pb-lg-4">
        <div class="price-final-total fs-1 fw-700">{{ minCalc | toCurrency }} — {{ maxCalc | toCurrency }}</div>
        <div class="timeframe-final-total fs-5 fw-600 text-muted">{{ timeframeString }} Project Timeframe</div>
        <button class="btn text-primary fw-600 fs-5" v-on:click="restartEstimation">Restart?</button>
      </div>
    </div>
    <div class="send_email_card rounded-5 d-flex justify-content-between align-items-center py-2 py-lg-4 px-2 px-lg-5 mt-5 mx-0" style="background-color: #F6F7FB; bottom: 1rem;">
      <div class="col-12 row py-3 mx-0">
        <div class="col-12 col-lg-3 fs-1 fw-700 text-center text-lg-start px-4 d-flex align-items-center" style="line-height: 3rem;">
          Save Your Estimate
        </div>
        <div class="col-12 col-lg-9 mt-3 mt-lg-0">
          <div class="first-row d-flex flex-wrap flex-lg-nowrap justify-content-center">
            <input v-on:blur="checkSubmitBtn" name="your-name" v-model="nameInput" v-bind:class="{'border-danger': nameIsNull, 'border-0': !nameIsNull}" v-bind:disabled="post_sent" class="form-control px-3 py-3 mx-2 fw-500 rounded-4 my-1 my-lg-0" placeholder="Your Name" />
            <input v-on:blur="checkSubmitBtn" name="your-email" type="email" v-model="emailInput" v-bind:class="{'border-danger': emailIsInvalid, 'border-0': !emailIsInvalid}" v-bind:disabled="post_sent" class="form-control px-3 py-3 mx-2 fw-500 rounded-4 my-1 my-lg-0" placeholder="Your Email" />
            <button v-bind:class="{disabled: submit_disabled, 'button--loading': submit_loading}" v-on:click="recaptcha" class="btn btn-primary fw-700 fs-5 px-6 py-3 rounded-4 mx-2 my-2 my-lg-0 position-relative">
              <span class="button__text">{{ submit_text }}</span>
            </button>
          </div>
          <div class="second-row col-12 px-2 pt-3" style="line-height: 1.4rem;">
            <div class="form-check">
              <input v-bind:disabled="post_sent" v-on:change="checkSubmitBtn" v-model="agreeToPolicy" class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style="width: 1.2rem; height: 1.2rem;">
              <label class="form-check-label text-muted ps-1" style="text-align: justify;" for="flexCheckDefault">
                By requesting this report, you are agreeing to Reev’s <a class="disclosure-link" target="_blank" href="https://reev.ca/privacy/">Privacy Policy</a> and <a class="disclosure-link" target="_blank" href="https://reev.ca/terms/">Terms of Service</a> and receiving an accurate quote based on your answers. Your email and name will not be shared with any third party. This site is protected by reCAPTCHA and the Google <a class="disclosure-link" target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a class="disclosure-link" target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  props: {

  },
  data() {
    return {
      settings: this.$store.state.json_data.settings,
      totalPrice: 0,
      totalTime: 0,
      minCalc: 0,
      maxCalc: 0,
      timeframeMinCalc: 0,
      timeframeMaxCalc: 0,
      timeframeString: "—",
      post_response: null,
      emailIsInvalid: false,
      nameIsNull: false,
      submit_disabled: true,
      nameInput: '',
      emailInput: '',
      agreeToPolicy: false,
      submit_text: 'Submit',
      submit_loading: false,
      post_sent: false
    }
  },
  mounted() {

    if (this.$store.state.post_sent) {
      this.submit_text = 'Sent!'
      this.post_sent = true
    }


    if (this.$store.state.questions_answers.length <= 0 || !this.$store.state.questions_answers) {
      this.$router.push({ name: 'Home' })
    }

    this.totalTime = this.$store.state.initial_time
    this.totalPrice = this.$store.state.initial_price
    this.$store.state.questions_answers.forEach(question => {
      this.totalTime += question.total_time
      this.totalPrice += question.total_price
    })
    this.calculateProgram()

    this.sendNumber()


  },
  methods: {
    async recaptcha() {
      if (this.post_sent)
        return
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute('6Lec1R8eAAAAAMiuw0G4Dz77xNqi49sJVnwNrPwj', {action: 'validate_captcha'})
        await this.sendEmail(token)
      });
    },
    async sendNumber() {
      try {
        let response = await fetch(this.$endpointURL + '/api/index.php', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            price: this.totalPrice
          })
        });
        this.post_response = await response.json();
      } catch (error) {
        console.log(error);
      }
    },

    async sendEmail(recaptcha_token) {
      this.submit_loading = true

      let emailQuestionsArray = []
      const questions = this.$store.state.json_data.questions
      const answers = this.$store.state.questions_answers
      questions.forEach(function addQuestions(question, index) {
        let options = []
        answers[index].options.forEach(function addOptions(answer_option) {
          options.push({
            option: question.options[parseInt(answer_option)].option_text
          })
        })
        emailQuestionsArray.push({
          title: question.title,
          subtitle: question.body,
          options: options
        })
      });

      try {
        let response = await fetch(this.$endpointURL + '/api/index.php', {
          method: 'PUT',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'X-Requested-With': 'xmlhttprequest'
          },
          body: JSON.stringify({
            email: this.emailInput,
            token: recaptcha_token,
            name: this.nameInput,
            answers: {
              startingPrice: this.minCalc,
              endingPrice: this.maxCalc,
              startingTime: Math.round(this.timeframeMinCalc),
              endingTime: Math.round(this.timeframeMaxCalc),
              questions: emailQuestionsArray,
            }
          })
        });
        this.post_response = await response.json();
        console.log(this.post_response)
        this.submit_loading = false
        this.submit_text = 'Sent!'
        this.submit_disabled = true
        if (this.post_response.result) {
          this.post_sent = true
          this.$store.state.post_sent = true
          sessionStorage.setItem('post_sent', '1')
        }

      } catch (error) {
        console.log(error);
      }
    },

    checkSubmitBtn: function () {

      let submit_disabled = false

      if (this.nameInput !== '') {
        this.nameIsNull = false
      } else {
        this.nameIsNull = true
        submit_disabled = true
      }

      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.emailInput)) {
        this.emailIsInvalid = false
      } else {
        this.emailIsInvalid = true
        submit_disabled = true
      }

      if (!this.agreeToPolicy)
        submit_disabled = true

      if (submit_disabled)
        this.submit_disabled = true
      else
        this.submit_disabled = false
    },
    submitEstimation: function() {
      this.$refs.recaptcha.execute();

    },
    restartEstimation: function() {
      this.$store.commit('clearState')
      this.$router.push({ name: 'Questions', params: { index: '0' } });
    },
    calculateProgram: function () {
      const calculatingTotalTime = this.totalTime
      const calculatingTotalPrice = this.totalPrice
      if ( calculatingTotalPrice === 0 ) {
        this.minCalc = 0
        this.maxCalc = 0
      } else {
        this.minCalc = calculatingTotalPrice - (calculatingTotalPrice * this.settings.total_price_diff / 100)
        this.maxCalc = calculatingTotalPrice + (calculatingTotalPrice * this.settings.total_price_diff / 100)
      }

      if ( calculatingTotalTime === 0 ) {
        this.timeframeMinCalc = 0
        this.timeframeMaxCalc = 0
        this.timeframeString = "—"
      } else {
        this.timeframeMinCalc = calculatingTotalTime - (calculatingTotalTime * this.settings.total_time_diff / 100)
        this.timeframeMaxCalc = calculatingTotalTime + (calculatingTotalTime * this.settings.total_time_diff / 100)
        if ( this.timeframeMinCalc/24/7 < 1 ) {
          this.timeframeMinCalc = this.timeframeMinCalc/24
          this.timeframeMaxCalc = this.timeframeMaxCalc/24
          this.timeframeString = Math.round(this.timeframeMinCalc) + " — " + Math.round(this.timeframeMaxCalc) + " Days"
        } else {
          this.timeframeMinCalc = this.timeframeMinCalc/24/7
          this.timeframeMaxCalc = this.timeframeMaxCalc/24/7
          if (Math.round(this.timeframeMinCalc) === Math.round(this.timeframeMaxCalc))
            this.timeframeString = Math.round(this.timeframeMinCalc) + " Weeks"
          else
            this.timeframeString = Math.round(this.timeframeMinCalc) + " to " + Math.round(this.timeframeMaxCalc) + " Weeks"
        }

      }
    }
  }
}
</script>
